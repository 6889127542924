import { useEffect, useState } from "react";
import { closeIcon } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchIframeData, resetfetchIframeData } from "../store/actions";
import { encodeFormData } from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { t } from "i18next";

// Functional component for Information Governance
const SCVPopup = (props: any) => {

    const [selectedSVCId, setSelectedSVCId] = useState<any>(null);

    return (<div className="xan-modal">
        <div
            className="modal fade show"
            id="exampleModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document" style={{ width: "30%" }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                        <button className="btn btn-transparent close" onClick={() => {
                            props.onSubmit("", null);
                        }} >
                            <img src={closeIcon} alt="close" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            {props.data && props.data.gridRows.map((item: any, index: number) => {
                                return <div>
                                    <label className="d-flex pb-3">
                                        <div className="p-3">
                                            <input type="radio" id={"checkbox" + index} name={"case"} onChange={(e) => setSelectedSVCId(item.values[1])} />
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="m-0"><b>{item.values[1]}</b></p>
                                            <p>{item.values[2]}</p>
                                        </div>
                                    </label>
                                </div>
                            })
                            }
                        </div>

                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <button className="btn btn-primary" disabled={selectedSVCId !== null ? false : true} onClick={() => {
                                    if (selectedSVCId === null) {
                                        toast.error("Please select SCVID");
                                    } else {
                                        props.onSubmit("", selectedSVCId)
                                    }
                                }}>Select SCV</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default SCVPopup;