import { useState } from "react";
import { toast } from "react-toastify";

import { useEffect } from "react";
import { getNewAxiosInstance } from "../../../utils";

const SelectPicker = (props: any) => {

    // getNewAxiosInstance
    const [pickList, setPickList] = useState<any>(null);

    useEffect(() => {
        // Fetch Information pickList
        try {
            let axiosInstance = getNewAxiosInstance(props.token);

            axiosInstance.get(props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""), {

                params: {
                    dspId: props.dspId,
                    controlId: props.items.controlId,
                    oneViewReference: props.items.selectedCaseReference
                }
            })
                .then((response: any) => {
                    setPickList(response.data.values);
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });

        } catch (e: any) {
            toast.error(e.message);
        }
    }, []);


    return (
        <div className="column" key={props.items.controlId}>
            <h6 className="involve-case-title">{props.items.label}</h6>
            <div className="involve-case-container">
                <select
                    disabled={!props.items.isEditable}
                    className="involve-case"
                    name={props.items.name}
                    key={props.items.controlId}
                    onChange={props.handleChange}
                >
                    {pickList?.map((option: any) => (
                        <option value={option.valueToStore} key={option.valueToStore} selected={option.valueToStore === props.selected ? true : false}>
                            {option.textToDisplay === "" ? "Select" : option.textToDisplay}
                        </option>
                    ))}
                </select>
            </div>
        </div>)
}
export default SelectPicker;