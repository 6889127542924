import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchFeedbackCSADDRequest, fetchFeedbackCSEditRequest, fetchFeedbackCSRequest, resetFeedbackCSAdd, resetFeedbackCSEdit } from "../../views/case-summary/store/actions";
import { TOKEN, getData } from "../../utils/Storage";
import Button from "../atoms/button";
import { closeIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

// Functional component for Add feedback
const FeedbackPopup = (props: any) => {
    // State for storing the "involve case" data
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const dspId = props.dspId;
    const caseSummaryType = props.caseSummaryType;
    const oneViewReference = props.oneViewReference;
    const feedbackId = props.feedbackId;

    const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
    const [sectionId, setSectionId] = useState<any>('')
    const [notes, setNotes] = useState(props.notes)

    const feedbackCsAdd: any = useSelector<any>((state) => state.feedbackCsAdd);
    const feedbackCsEdit: any = useSelector<any>((state) => state.feedbackCsEdit);



    useEffect(() => {
        getData(TOKEN).then((res: any) => {
            let token;
            if (res?.provider === "msal") {
                token = { isLegacy: false, token: res.token }
            } else {
                token = { isLegacy: true, token: res.token };
            }
            setToken(token);
        })
    }, [])


    const AddfeedbackCsApiCall = () => {
        let body = {
            dspId: dspId,
            caseSummaryType: caseSummaryType,
            sectionId: sectionId,
            oneViewReference: oneViewReference,
            notes: notes,
        };

        dispatch(fetchFeedbackCSADDRequest({ token: token, params: body }));
    }

    const EditfeedbackCsApiCall = () => {
        let body = {
            feedbackId: feedbackId,
            dspId: dspId,
            caseSummaryType: caseSummaryType,
            sectionId: sectionId,
            oneViewReference: oneViewReference,
            notes: notes,

        };

        dispatch(fetchFeedbackCSEditRequest({ token: token, params: body }));
    }

    useEffect(() => {
        if (
            feedbackCsAdd.isSuccess &&
            feedbackCsAdd.data !== null
        ) {
            feedbackCsApiCall(token)
            props.onCancel()
            dispatch(resetFeedbackCSAdd());
        } else if (feedbackCsAdd.isError) {
            toast.error(
                feedbackCsAdd.data.error.data.title ||
                feedbackCsAdd.data.error.status
            );
            dispatch(resetFeedbackCSAdd());
        }
    }, [feedbackCsAdd]);

    const feedbackCsApiCall = (token: any) => {
        let body = {
            token: token,
            dspId: dspId,
            caseSummaryType: caseSummaryType,
            oneViewReference: oneViewReference
        };
        dispatch(fetchFeedbackCSRequest({ token: token, params: body }));
    }


    useEffect(() => {
        if (
            feedbackCsEdit.isSuccess &&
            feedbackCsEdit.data !== null
        ) {
            feedbackCsApiCall(token)
            props.onCancel()
            dispatch(resetFeedbackCSEdit());
        } else if (feedbackCsEdit.isError) {
            toast.error(
                feedbackCsEdit.data.error.data.title ||
                feedbackCsEdit.data.error.status
            );
            dispatch(resetFeedbackCSEdit());
        }
    }, [feedbackCsEdit]);

    const renderHeader = () => {
        return (
            <div className="modal-header">
                <h5 className={"aside-top-bar " + (props.title !== '' ? "justify-content-sm-between" : "")} id="exampleModalLabel">
                    {props.title}
                </h5>
                <button
                    style={{ marginRight: 12, backgroundColor: '#5464B0', borderColor: '#5464B0', borderWidth: 1 }}
                    className="btn btn-transparent"
                    onClick={() => props.onCancel()
                    }
                >
                    <img src={closeIcon} alt="Close icon" />
                </button>
            </div>
        )

    }

    const renderBody = () => {
        return (
            <div className="modal-body">

                <div className="column px-3">
                    <h6 className="involve-case-title">{'Select Section'}</h6>
                    <div className="involve-case-container">
                        <select
                            className="involve-case"
                            onChange={(e) => {
                                const id = props.sectionList.find(
                                    (options: any, index: any) => {
                                        return e.target.value === options.value;
                                    }
                                );
                                setSectionId(id.value);
                            }}
                        >
                            <option value={""}>Select</option>
                            {props.sectionList.map((options: any, index: any) => {
                                return (
                                    <option value={options.value} key={options.value}>
                                        {options.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="filter-fields px-3 pb-3">
                    <h3 className="title">{t('NOTES')}</h3>
                    <input aria-multiline={true} type="text"
                        name={"notes"}
                        id={"notes"}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)} />
                </div>
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <div className="modal-footer">
                <Button
                    key={""}
                    title={t('CANCEL')}
                    className="button cancel-button"
                    type="button"
                    onClick={() => props.onCancel()}

                />
                <Button
                    key={""}
                    title={props.isFrom == 'edit' ? feedbackCsEdit.isFetching : feedbackCsAdd.isFetching ? t('REQUEST') : t('SAVE')}
                    className={"button save-button width6" + (feedbackCsEdit.isFetching || feedbackCsAdd.isFetching ? "btn-disabled width6" : "")}
                    type="submit"
                    disabled={props.isFrom == 'edit' ? feedbackCsEdit.isFetching : feedbackCsAdd.isFetching}

                />
            </div>
        )
    }
    return (
        <div className="xan-modal feedback-popup">
            <div
                className="modal fade show"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {renderHeader()}
                        <form
                            className="information-governance-container"
                            onSubmit={(e) => {
                                e.preventDefault();

                                if (props.isFrom == 'edit') {
                                    EditfeedbackCsApiCall()
                                } else {
                                    AddfeedbackCsApiCall()
                                }
                            }}
                        >
                            {renderBody()}
                            {renderFooter()}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default FeedbackPopup;
